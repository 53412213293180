<template>
  <div class="section">
    <div class="container">
      <div class="row row-split-content">
        <div class="content-width-small" style="text-align: center;">
          <h6 class="subheading text-primary-1">{{content.pre_header}}</h6>
          <h3 class="h1">{{content.main_header}}!</h3>
          <div
            style="text-align: justify;"
          >{{content.main_content}}</div>
          <br/>
          <a v-if="!ready" href="#" class="button w-inline-block">
            <div>
              <button class="button w-inline-block prep" disabled>
                <div>
                  Preparing
                  <i class="fas fa-sync fa-spin"></i>
                </div>
              </button>
            </div>
          </a>
          <a v-else href="/" class="button w-inline-block">
            <div>
              <a class="button w-inline-block">
                <div>
                  Enter
                  <i class="fas sign-in-alt"></i>
                </div>
              </a>
            </div>
          </a>
        </div>
        <div class="content-width-large">
          <div data-animation="slide" data-duration="500" data-infinite="1" class="slider w-slider">
            <div class="w-slider-mask">
              <div class="w-slide">
                <p>{{content.video_helper_text}}</p>
              <video width="400" v-bind:src="content.video" controls/>
              </div>
            </div>

            <div class="display-none w-slider-nav w-round"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileService from "@/services/profileService.js";
import PageService from "@/services/pageService.js";
export default {
  data() {
    return {
      polling: null,
      ready: false,
      content: [],
      CompanyName: process.env.VUE_APP_COMPANY_NAME
    };
  },
  methods: {
    pollData() {
      this.polling = setInterval(() => {
        var userId = this.$auth.user.sub;
        this.email = this.$auth.user.email;
        // eslint-disable-next-line no-undef
        mixpanel.identify(userId);
        var vm = this;
        this.$auth.getTokenSilently().then(function(result) {
          vm.bearer = result;
          ProfileService.getUserInfo(userId, result)
            .then(response => {
              var status = response.data.app_metadata.status;

              if (status == "current") {
                vm.ready = true;
              } else {
                vm.ready = false;
              }
            })
            .catch(error => {
              console.log(
                "there was an error getting the user metadata",
                error
              );
            });
        });
      }, 5000);
    }
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  created() {
    this.pollData();
    var today = new Date().toISOString();
    PageService.getPage("welcome-page")
      .then((response) => {
        this.content = response.data.data.fields;
      })
      .catch((error) => {
        console.log("there was an error getting the content", error);
      });
    // eslint-disable-next-line no-undef
    mixpanel.track("Sign Up", {"Date": today});

  }
};
</script>

<style scoped>
.prep {
  cursor: not-allowed;
}
</style>